import $ from "jquery";

import QuestionnaireForm from "./questionnaire-form";

$(function() {
  const questionnaire_form = new QuestionnaireForm("form#questionnaire_flow_questions");

  if (process.env.NODE_ENV === "development") {
    window.questionnaire_form = questionnaire_form;
  }
});
