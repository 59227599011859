import FormInterface, { AFTER_SETUP } from './form-interface';

import { autobind } from 'core-decorators';

export default class RegistrationForm extends FormInterface {
  get childBornOn() {
    return this.getValue('respondent[child_born_on]');
  }

  get childDueOn() {
    return this.getValue('respondent[child_due_on]');
  }

  get childIsPremature() {
    return this.getValue('respondent[child_premature]') === 'yes';
  }

  get consentGivenAt() {
    return this.getValue('respondent[consent_given_at]');
  }

  @autobind
  restrictDueDate() {
    const newMinimum = this.childBornOn;

    if (newMinimum) {
      this.$childDueOn.attr('min', newMinimum);
    }
  }

  @autobind
  toggleChildDueOn() {
    this.$childDueOnWrapper.toggle(this.childIsPremature);
  }

  [AFTER_SETUP]() {
    this.$childBornOn       = this.$('[name="respondent[child_born_on]"]');
    this.$childDueOn        = this.$('[name="respondent[child_due_on]"]');
    this.$childDueOnWrapper = this.$('.input.respondent_child_due_on');
    this.$childPremature    = this.$('select[name="respondent[child_premature]"]');

    this.$childBornOn.on('input', this.restrictDueDate);
    this.$childPremature.on('input', this.toggleChildDueOn);

    this.restrictDueDate();
    this.toggleChildDueOn();
  }
}
