import $ from 'jquery';

import RegistrationForm from './registration-form';

$(function() {
  const registration_form = new RegistrationForm('form#new_respondent', 'respondent');

  if (process.env.NODE_ENV === 'development') {
    window.registration_form = registration_form;
  }
});
