import $ from 'jquery';
import ahoy from 'utility/ahoy';
import 'imports-loader?jQuery=jquery!jquery.idle';

import FieldWrapper from "./field-wrapper";

const SETUP = Symbol("SETUP");
export const AFTER_SETUP = Symbol("AFTER_SETUP");

const IDLE_HOOKS = {
  onIdle: "activity.idle",
  onActive: "activity.back",
  onHide: "window.hide",
  onShow: "window.show"
};

export default class FormInterface {
  constructor(selector) {
    this.$form = $(selector);

    this.exists = this.$form.length > 0

    this[SETUP]();
  }

  ["$"](selector) {
    return $(selector, this.$form);
  }

  get ahoyProperties() {
    return this.$form.data("ahoyProperties") || {};
  }

  get elements() {
    return this.exists ? this.form.elements : [];
  }

  get ahoy() {
    return ahoy;
  }

  getInput(name) {
    if (!this.exists) return null;

    return this.elements.namedItem(name);
  }

  getFormData() {
    return [].reduce.call(this.elements, (data, element) => {
      data[element.name] = element.value;

      return data;
    }, {});
  }

  getValue(name) {
    if (!this.exists) return null;

    const input = this.getInput(name);

    if (input && typeof RadioNodeList !== "undefined" && input instanceof RadioNodeList) {
      return Array.prototype.reduce.call(input, (value, node) => {
        if (node.type === "checkbox" && node.checked) {
          return node.value;
        } else if (node.tagName === "SELECT") {
          return node.value;
        } else {
          return value || node.value;
        }
      }, null)
    }

    return input ? input.value : null;
  }

  getWrapperFor(selector) {
    return new FieldWrapper(this, selector);
  }

  disableSubmit() {
    this.toggleSubmit(false);
  }

  enableSubmit() {
    this.toggleSubmit(true);
  }

  toggleSubmit(value) {
    if (!this.exists) return;

    if (value === true) {
      this.$submit.removeAttr("disabled");
    } else if (value === false) {
      this.$submit.attr("disabled", "disabled");
    } else {
      this.toggleSubmit(!this.$submit.is(":disabled"))
    }
  }

  buildIdleWatcher(prefix, options = {}) {
    // We consider a user idle after 3 minutes of no interactions
    const config = { idle: (3 * 60000), ...options };

    for (const [hook, suffix] of Object.entries(IDLE_HOOKS)) {
      const event = `${prefix}.${suffix}`;
      const props = this.ahoyProperties;

      config[hook] = () => {
        if (process.env.NODE_ENV === "development") {
          console.debug("firing event %s", event);
        }

        ahoy.track(event, props);
      };
    }

    $(document).idle(config);
  }

  [SETUP]() {
    if (!this.exists) return;
    if (this.form) return;

    this.form = this.$form[0];

    this.$submit = this.$("button:submit");

    this[AFTER_SETUP]();
  }

  [AFTER_SETUP]() {}
}
