import $ from 'jquery';

import flatpickr from 'flatpickr';

$(function() {
  const inputs = $('input[type="date"]');

  if (!inputs || +(inputs.length) <= 0) {
    return;
  }

  if (inputs.get(0).type !== 'date') {
    inputs.each(function() {
      const $input = $(this);

      flatpickr(this, {
        minDate: $input.attr('min'),
        maxDate: $input.attr('max')
      });
    });
  }
});
