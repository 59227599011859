import 'flatpickr/dist/flatpickr.min.css';
import 'styles/main.scss';

import $ from 'jquery';
import 'imports-loader?jQuery=jquery!jquery.idle';
import Rails from 'rails-ujs';

import 'ujs-bindings/alerts';
import 'ujs-bindings/flatpickr';
import 'ujs-bindings/headroom';

import 'pages/questionnaire-flow-questions';
import 'pages/respondent-registration';
import 'pages/respondent-demographics';
import 'pages/results-email';

import ahoy from 'utility/ahoy';
import 'utility/menu';

Rails.start();

ahoy.configure({
  //cookieDomain: window.location.hostname,
  visitsUrl: "/api/barrels",
  eventsUrl: "/api/cabbages",
});

ahoy.trackView();

if (process.env.NODE_ENV === 'development') {
  ahoy.debug(true);

  window.ahoy = ahoy;
  window.Rails = Rails;
}
