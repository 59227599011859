import $ from 'jquery';

import DemographicsForm from './demographics-form';

$(function() {
  const demographics_form = new DemographicsForm('form#respondent_demographics', 'respondent');

  if (process.env.NODE_ENV === 'development') {
    window.demographics_form = demographics_form;
  }
});
