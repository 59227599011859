import $ from 'jquery';

$(function() {
  const menuElement = document.getElementsByClassName("nav-drawer")[0];
  const menuClass = "open";
  const bodyClass = "scroll-lock";
  const hamburger = document.getElementsByClassName("hamburger")[0];
  const closeButton = document.getElementsByClassName("close-mobile-menu")[0];

  if (hamburger) {
    hamburger.addEventListener("click", toggleMenu);
  }

  if (closeButton) {
    closeButton.addEventListener("click", toggleMenu);
  }

  function toggleMenu() {
    menuElement.classList.contains(menuClass)
      ? closeMenu()
      : openMenu()
  }

  function openMenu() {
    menuElement.classList.add(menuClass);
    menuElement.setAttribute('aria-hidden', false);
    document.body.classList.add(bodyClass);
  }

  function closeMenu() {
    menuElement.classList.remove(menuClass);
    menuElement.setAttribute('aria-hidden', true);
    document.body.classList.remove(bodyClass);
  }
});
