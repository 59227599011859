import FormInterface, { AFTER_SETUP } from './form-interface';

import { autobind } from 'core-decorators';

export default class DemographicsForm extends FormInterface {
  get hasDisabilityOrDelay() {
    return this.getValue('respondent[child_has_disability_or_delay]') === 'yes';
  }

  get receivesSpecialService() {
    return this.getValue('respondent[child_receives_special_service]') === 'yes';
  }

  @autobind
  toggleDisabilityDependencies() {
    this.$diagnosticianWrapper.toggle(this.hasDisabilityOrDelay);
    this.$disabilityDetailsWrapper.toggle(this.hasDisabilityOrDelay);
  }

  @autobind
  toggleSpecialServiceDependencies() {
    this.$specialServiceDetailsWrapper.toggle(this.receivesSpecialService);
  }

  [AFTER_SETUP]() {
    this.$diagnosticianWrapper          = this.$('.input.respondent_diagnostician');
    this.$disabilityDetailsWrapper      = this.$('.input.respondent_child_disability_or_delay_details');
    this.$specialServiceDetailsWrapper  = this.$('.input.respondent_child_special_services_details');

    this.$('[name="respondent[child_has_disability_or_delay]"]').on('input', this.toggleDisabilityDependencies);
    this.$('[name="respondent[child_receives_special_service]"]').on('input', this.toggleSpecialServiceDependencies);

    this.toggleDisabilityDependencies();
    this.toggleSpecialServiceDependencies();
  }
}
