import FormInterface, { AFTER_SETUP } from './form-interface';

import { autobind } from 'core-decorators';

export default class ProvideEmailForm extends FormInterface {
  get shouldSendResults() {
    const value = this.getValue("receipt[send_results]");

    return value === "1" || value === "true";
  }

  @autobind
  toggleEmailField() {
    const required = this.shouldSendResults;

    [this.$email, this.$emailConfirmation].forEach(function(wrapper) {
      wrapper.toggle(required);
      wrapper.toggleRequired(required);
    }, this);
  }

  [AFTER_SETUP]() {
    this.$email             = this.getWrapperFor('input[name="receipt[email]"]');
    this.$emailConfirmation = this.getWrapperFor('input[name="receipt[email_confirmation]"]');

    this.$sendResults = this.$('input[name="receipt[send_results]"]');

    this.$sendResults.on('change', this.toggleEmailField);

    this.toggleEmailField();
  }
}
