import { autobind } from 'core-decorators';

export default class FieldWrapper {
  constructor(form, selector) {
    this.form     = form;
    this.selector = selector;

    this.$field   = form.$(selector);
    this.$label   = this.$field.prev('label');
    this.$wrapper = this.$field.closest('.input');

    this.$group   = this.$field.add(this.$label).add(this.$wrapper);
  }

  toggle(on = false) {
    this.$group.toggle(on);

    return this;
  }

  toggleRequired(on = false) {
    this.$group.toggleClass('optional', !on);
    this.$group.toggleClass('required', on);
    this.$field.attr('required', on);
  }
}
