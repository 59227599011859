import $ from 'jquery';

import ProvideEmailForm from './provide-email-form';

$(function() {
  const email_form = new ProvideEmailForm('form#new_receipt', 'receipt');

  if (process.env.NODE_ENV === 'development') {
    window.email_form = email_form;
  }
});
