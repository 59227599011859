import 'nodelist-foreach-polyfill';
import Headroom from 'headroom.js';

export function attachHeadroom(element) {
  const previousHeader = element.previousElementSibling;
  const offset = previousHeader ? previousHeader.offsetHeight : 0;

  const headroom = new Headroom(element, { offset });

  headroom.init();
}

export function attachAllHeadrooms() {
  document.querySelectorAll('[data-headroom]').forEach(attachHeadroom);
}

if (document.readyState === "loading") {
  document.addEventListener('DOMContentLoaded', () => attachAllHeadrooms())
} else {
  attachAllHeadrooms();
}
