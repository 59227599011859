import FormInterface, { AFTER_SETUP } from "./form-interface";

import { autobind } from "core-decorators";

import $ from "jquery";
import ahoy from "ahoy.js";
import "imports-loader?jQuery=jquery!jquery.idle";

export default class QuestionnaireForm extends FormInterface {
  @autobind
  toggleInputFromRadioChange(ev) {
    const $input = this.$(ev.target);

    this.toggleInputFromRadio($input);
  }

  @autobind
  toggleInputFromRadio(input) {
    const $input = this.$(input);

    const $answerWrapper = $input.closest(".answer-inputs");

    const $inputWrapper = $answerWrapper.find(".input.questionnaire_state_domain_interval_responses_input, .input.extended_item_set_extended_items_response_input");

    $inputWrapper.toggle($input.is(":checked") && $input.data("requiresInput"));
  }

  toggleAllInputs() {
    this.$radios.each((_index, el) => this.toggleInputFromRadio(el));
  }

  [AFTER_SETUP]() {
    this.$radios = this.$("input[data-requires-input]:radio");

    this.$radios.on("change", this.toggleInputFromRadioChange);

    this.toggleAllInputs();

    this.buildIdleWatcher("questionnaire");
  }
}
